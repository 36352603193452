import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider, Stack, TextField } from '@mui/material';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import {APIProvider,Marker, Map} from '@vis.gl/react-google-maps';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';


const width = "xxl"
export default function Us() {

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
      <>
      <Container maxWidth={width} sx={{
        backgroundImage: "radial-gradient(circle at top left, rgba(40, 40, 40, 0.79) 0%, rgba(40, 40, 40, 0.86) 100%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/image_hero.webp?alt=media&token=3e1d6b4b-b54a-48e7-b612-828e2bf36079') !important",
        pt:24,
        pb:24,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        
        }}>
        <Grid  container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={0} sm={0} md={1} align="center">
          
          </Grid>
          <Grid item xs={12} sm={12} md={4} align="left"> 
            <Stack spacing={2} >
              <Typography variant="h2" align="left" color="white" sx={{fontWeight:"bolder"}}>
                TodoFix
              </Typography>
              <Divider style={{ background: 'white' }}/>
              <Typography variant="h5" gutterBottom color="white" sx={{fontWeight:"bold"}}>
                "La fuerza del equipo reside en cada miembro, la fuerza de cada miembro es el equipo".
              </Typography>
              <Typography variant="body1" align="right" gutterBottom color="white" >
                -Phil Jackson-
              </Typography>
              <Link to="/projects"> 
                <Button variant="contained">
                    Conoce nuestro trabajo
                </Button>
              </Link>
              
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} align="center">
          
          </Grid>
          <Grid item xs={12} sm={12} md={4} align="center">
           <Stack spacing={2} >
                 {/*<Typography variant="h3" align="left" color="yellow" sx={{fontWeight:"bolder"}}>
                Lo que nos hace
                </Typography>
            <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{
                    height: 56,
                    width: 56,
                    maxHeight: { xs: 56, md: 56 },
                    maxWidth: { xs: 56, md: 56 },
                  }}
                  alt="The house from the offer."
                  src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/ic_construction.webp?alt=media&token=880c3dbb-55a3-4eac-ab3e-8c1de6888747"
                />
                <Typography variant="body1" color="white">
                Ofrecemos soluciones integrales para la vivienda residencial y comercial, interior y exterior. 
                </Typography>
            </Stack>*/}
              <Stack direction="row" spacing={2}alignItems={"center"}>
                  <Box
                    component="img"
                    sx={{
                      height: 128,
                      width: 120,
                      maxHeight: { xs: 128, md: 128 },
                      maxWidth: { xs: 128, md: 128 },
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/employees%2FScreenshot%202024-09-16%20at%2011.12.29%E2%80%AFp.m..png?alt=media&token=0e47145f-03c1-4c5e-933e-f02a213d74b2"
                  />
                  <Stack  spacing={2} >
                    <Typography variant="h6" color="white" textAlign={"left"}>
                      Lic. Jeannineé Silva
                    </Typography>
                    <Typography variant="h6" color="white" textAlign={"left"}>
                    Fundador
                    </Typography>
                    <Typography variant="caption" color="white" textAlign={"left"}>
                      "Ser líder no es llegar primero,es hacer que lleguen y crezcan juntos".
                    </Typography>
                  </Stack>
              </Stack>
              <Stack direction="row" spacing={2}alignItems={"center"}>
                  <Box
                    component="img"
                    sx={{
                      height: 128,
                      width: 120,
                      maxHeight: { xs: 128, md: 128 },
                      maxWidth: { xs: 128, md: 128 },
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/employees%2FScreenshot%202024-09-20%20at%2010.20.25%E2%80%AFp.m..png?alt=media&token=9d003e9b-e6d7-449f-90bc-36518d00b296"
                  />
                  <Stack  spacing={2} >
                    <Typography variant="h6" color="white" textAlign={"left"}>
                      Lic. Miguel Luna
                    </Typography>
                    <Typography variant="h6" color="white" textAlign={"left"}>
                      Fundador
                    </Typography>
                    <Typography variant="caption" color="white" textAlign={"left"}>
                    "El camino al éxito está lleno de grandes cambios, y los grandes cambios empiezan con pequeñas decisiones".
                    </Typography>
                  </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={1} align="center"></Grid>
        </Grid>
      </Container>

      <Container sx={{pt:8, pb:1}}>
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={12}>   
            <Stack spacing={2} align="center">
              <Typography variant="h2" align="center" color="black" fontWeight="bold">
              ¿Quiénes somos? 
              </Typography>

              <Divider></Divider>
              <Typography variant="h6" align="left" color="text.secondary">
                Nuestra pasión y habilidades en el ámbito de la construcción 
                nos llevo a crear TodoFix, una empresa que resuleve las tareas 
                de mantenimiento y construcción en oficinas, residencias o negocios.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={width}sx={{pt:4, pb:2,}}>
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={5}>   
          </Grid>
          <Grid item xs={12} sm={12} md={1} sx={{backgroundColor:"red"}}>
           
          </Grid>
          <Grid item xs={12} sm={12} md={5}>   
          </Grid>
        </Grid>
      </Container>

      <Container sx={{pt:8, pb:8}}>
        <Grid container spacing={2} alignItems="top" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={6}>   
            <Stack spacing={2} align="center">
              <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{
                    height: 56,
                    width: 56,
                    maxHeight: { xs: 56, md: 56 },
                    maxWidth: { xs: 56, md: 56 },
                  }}
                  alt="The house from the offer."
                  src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/Nosotros%2Ftodofix_mission.png?alt=media&token=5d066938-a646-41b4-8c1f-2e3ba8c477d8"
                />
                <Typography variant="h4" align="left" color="text.secondary">
                Misión
                </Typography>
              </Stack>


              <Divider></Divider>
              <Typography variant="h6" align="left" color="text.secondary">
                Proporcionar servicios de mantenimiento, prevención, y corrección en bienes inmuebles, garantizando calidad y eficiencia en nuestros trabajos.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={2} align="center">
            <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{
                    height: 56,
                    width: 56,
                    maxHeight: { xs: 56, md: 56 },
                    maxWidth: { xs: 56, md: 56 },
                  }}
                  alt="The house from the offer."
                  src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/Nosotros%2Ftodofix_vision.png?alt=media&token=ade3450a-d963-4737-9747-1cd1e8aa2834"
                />
                <Typography variant="h4" align="left" color="text.secondary">
                Visión
                </Typography>
              </Stack>
              <Divider></Divider>
              <Typography variant="h6" align="left" color="text.secondary">
                Ser la solución ideal para mantenimiento, prevención y correción de de vienes inmuebles en el estado de puebla, posicionandonos como la marca insignia del estado.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container disableGutters maxWidth={width} 
        sx={{
          backgroundImage: "linear-gradient(0deg, rgb(0 0 0 / 20%) 10%, rgb(220 220 220) 90%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/background_3.jpg?alt=media&token=9d2d4bdc-9498-4a80-9204-f4c5096397ff') !important",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          }}
        >
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid  xs={12} sm={12} md={5}  sx={{backgroundColor:grey[700], p:8}}>
            <Stack spacing={2}>
             
                <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{
                    height: 56,
                    width: 56,
                    maxHeight: { xs: 56, md: 56 },
                    maxWidth: { xs: 56, md: 56 },
                  }}
                  alt="The house from the offer."
                  src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/Nosotros%2Ftodofix_values.png?alt=media&token=1607e906-8f0b-464a-aa49-dd27ade1747b"
                />
                  <Typography variant="h4" align="left" color="white" fontWeight="bold">
                    Nuestros valores
                </Typography>
              </Stack>
                
                <Stack direction="row" spacing={2}>
                    <DoubleArrowIcon sx={{color:"white"}}/>
                    <Stack spacing={2}>
                        <Typography variant="h6" align="left" color="white" fontWeight="bold">
                            Responsabilidad
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <DoubleArrowIcon sx={{color:"white"}}/>
                    <Stack spacing={2}>
                        <Typography variant="h6" align="left" color="white" fontWeight="bold">
                            Puntualidad
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <DoubleArrowIcon sx={{color:"white"}}/>
                    <Stack spacing={2}>
                        <Typography variant="h6" align="left" color="white" fontWeight="bold">
                            Innovación
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <DoubleArrowIcon sx={{color:"white"}}/>
                    <Stack spacing={2}>
                        <Typography variant="h6" align="left" color="white" fontWeight="bold">
                        Creatividad
                        </Typography>
                    </Stack>
                </Stack>
                
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={7} >
          </Grid>
          
        </Grid>
      </Container>

      <Container maxWidth={false} disableGutters>
        <Grid container alignItems="center" justifyContent="center" component="main"> 
          <Grid item xs={12} sm={12} md={6} >   
         

          <APIProvider apiKey={"AIzaSyBES2njD2zqvPzo9S_zwVzDZAkPM286Mi0"}>
            <Map

              style={{width: '100%', height: '577px'}}
              defaultCenter={{lat:  19.056248232911646, lng: -98.25806939274554}}
              defaultZoom={12}
              gestureHandling={'none'}
              disableDefaultUI={true}
            >
              <Marker position={{lat:  19.056248232911646, lng: -98.25806939274554}} />
            </Map>
          </APIProvider>

          </Grid>
          <Grid item xs={12} sm={12} md={6 } sx={{backgroundColor:"#252525"}}>
            <Stack spacing={2} sx={{padding:8}}>
              <Typography variant="h4" align="left" color="white" fontWeight="bolder">
                Contáctanos
              </Typography>
              <Divider flexItem sx={{ borderColor:"#fff", borderBottomWidth: '10px' ,backgroundColor:"#fff", maxWidth:"100px"}} />
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo"
                  name="email"
                  autoComplete="email"
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="telefono"
                  label="Teléfono"
                  name="telefono"
                  autoComplete="telephone"
                   variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="message"
                  label="Mensaje"
                  type="message"
                  id="message"     
                  multiline
                  rows={4}
                   variant="filled"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Enviar
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container> 
      </>
  );
}