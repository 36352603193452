import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider, TextField, Stack} from '@mui/material';
import { grey} from '@mui/material/colors';

import {APIProvider,Marker, Map} from '@vis.gl/react-google-maps';

import { app } from '../config/firebase';
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';

var servicios = [
  {
    name: "Impermeabilzación y pintura",
    description: "Cimentaciones, albañilería, estructuras de concreto y metálica.",
    url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/servicios%2Fpintura.png?alt=media&token=9d9de035-8c00-46d0-8986-14909138976e"
  },
  {
    name: "Jardinería",
    description: "Obras hidráulicas, obras eléctricas (baja y media tensión), CCTV y sistemas de seguridad, gas, aire acondicionado y obras sanitarias.",
    url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/servicios%2FJardineria.png?alt=media&token=498de223-f053-4d4a-844b-995a30b21b4b"
  },
  {
    name: "Albañilería",
    description: "Camiones de volteo, retroexcavadoras, pipas y grúa.",
    url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/servicios%2Falban%CC%83ileria.png?alt=media&token=53531927-592f-404b-bb2f-4605f3ed69a3"
  },
  {
    name: "Instalaciones",
    description: "Diseño de interiores, exteriores, paisajismo y  terracerías.",
    url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/servicios%2Finstalaciones.png?alt=media&token=f5c32564-94ac-46d8-a67b-b3701593f88e"
  },
  {
    name: "Limpieza y mantenimiento urbano",
    description: "Pintura, muros y cielos falsos, pisos, carpinteria, herreria, canceleria, impremeabilizaciones y jardineria.",
    url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/servicios%2FLimpieza.png?alt=media&token=6b2c2101-b722-4dd3-9330-683204499f48"
  },
  {
    name:"Remodelación",
    description:"",
    url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/servicios%2Fremodelacion.png?alt=media&token=989eafa8-868c-40b6-8cf0-411c65b68b02"
  }
]


const width = "xxl";
const db = getFirestore(app);

export default function Home() {

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = Object.fromEntries(new FormData(event.currentTarget));

    try {
      const docRef = doc(collection(db, 'contact-messages'));
      await setDoc(docRef, {
        ...data,
        id: docRef.id,
      });
    } catch (error) {
      console.error('not submitted');
      console.error(error);
    }
  };

  return (
      <>
      <Container maxWidth={width} sx={{
        backgroundImage: "radial-gradient(circle at top left, rgba(40, 40, 40, 0.79) 0%, rgba(40, 40, 40, 0.86) 100%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/image_hero.webp?alt=media&token=3e1d6b4b-b54a-48e7-b612-828e2bf36079') !important",
        pt:24,
        pb:24,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        }}>
        <Grid  container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={1} align="left">


          </Grid>
          <Grid item xs={12} sm={12} md={4} align="left">
            <Stack spacing={2} >
              <Box
              sx={{
                backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/todofix_logo.jpeg?alt=media&token=adbb9b1f-af89-4b23-9495-0742eb07745f') !important",
                pt:24,
                pb:24,
                backgroundSize: 'cover',
                backgroundPosition: 'center',

                }}
              ></Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} align="left"> </Grid>
          <Grid item xs={12} sm={12} md={5} align="left">
          <Stack spacing={2} >
            {/*<Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                color:"#1E3F62",
                fontWeight:"bolder"
              }}
            >
              Todo
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                   fontWeight:"bolder",
                  fontSize: 'inherit',
                  color: 'primary.main',
                  ...theme.applyStyles('dark', {
                    color: 'primary.light',
                  }),
                })}
              >
                Fix
              </Typography>
            </Typography>*/}
              <Divider style={{ background: 'white' }}/>
              <Typography variant="h3" gutterBottom color="white" >
              Vive tu vida nosotros la arreglamos.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container  sx={{backgroundColor:grey[200], pt:8, pb:8}}>
        <Grid container spacing={{ xs: 4, sm:4, md: 8 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {servicios.map((servicio, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
             <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{
                    height: 56,
                    width: 56,
                    maxHeight: { xs: 56, md: 56 },
                    maxWidth: { xs: 56, md: 56 },
                  }}
                  alt="The house from the offer."
                  src={servicio.url}
                />
                <Typography variant="h5" align="left" color="black">
                {servicio.name}
                </Typography>
            </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth={width}>
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={4}>
          </Grid>
          <Grid item xs={12} sm={12} md={8} sx={{backgroundColor:"#252525"}}>

          </Grid>
        </Grid>
      </Container>


      <Container maxWidth={width} sx={{pt:8, pb:8}}>
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={6}>

          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={0} align="center">
              <Typography variant="h3" align="left" color="black" fontWeight="bold">
                "El mantenimiento es un asunto muy fácil de complicar".
              </Typography>
              <Typography variant="body" align="right" color="black">
                   {"    -Gerard Wood-"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={width}sx={{pt:2,
        pb:2,}}>
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={3}>
          </Grid>
          <Grid item xs={12} sm={12} md={9} sx={{backgroundColor:"#252525"}}>

          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={width} sx={{
        backgroundImage: "radial-gradient(circle at top left, rgba(25, 25, 25, 0.99) 30%, rgba(25, 25, 25, 0.79) 100%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/background_2.jpg?alt=media&token=5c28ec66-d7cc-4591-95b4-6a2a370ec645') !important",
        pt:24,
        pb:24,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}

      >
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={6} >
            <Stack spacing={3}>
                <Typography variant="h4" align="left" color="white" fontWeight="bold">
                Puntualidad y profesionalismo en cada uno de nuestros proyectos.
                </Typography>
                <Divider style={{ background: 'white' }}/>
                <Typography variant="body1" align="left" color="white">
                Somos una empresa que resuleve las tareas 
                de mantenimiento y construcción en oficinas, residencias o negocios.
                </Typography>
              </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
              
              <Link 
              to="/us"
              > 
              <Button variant="special">
                  learn more
              </Button>
              </Link>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} disableGutters>
        <Grid container alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={6} >
            <APIProvider apiKey={"AIzaSyBES2njD2zqvPzo9S_zwVzDZAkPM286Mi0"}>
              <Map
                style={{width: '100%', height: '577px'}}
                defaultCenter={{lat:  19.056248232911646, lng: -98.25806939274554}}
                defaultZoom={12}
                gestureHandling={'none'}
                disableDefaultUI={true}
              >
                <Marker position={{lat:  19.056248232911646, lng: -98.25806939274554}} />
              </Map>
            </APIProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6 } sx={{backgroundColor:"#252525"}}>
            <Stack spacing={2} sx={{padding:8}}>
              <Typography variant="h4" align="left" color="white" fontWeight="bolder">
                Contáctanos
              </Typography>
              <Divider flexItem sx={{ borderColor:"#fff", borderBottomWidth: '10px' ,backgroundColor:"#fff", maxWidth:"100px"}} />
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo"
                  name="email"
                  autoComplete="email"
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="telefono"
                  label="Teléfono"
                  name="telefono"
                  autoComplete="telephone"
                   variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="message"
                  label="Mensaje"
                  type="message"
                  id="message"
                  multiline
                  rows={4}
                   variant="filled"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Enviar
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      </>
  );
}
