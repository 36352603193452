import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { CardActionArea, CardMedia, Divider,  Stack, TextField } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import {APIProvider,Marker, Map} from '@vis.gl/react-google-maps';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

var items = [
  {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/promocion_1.jpg?alt=media&token=f17d21f3-7bb5-4211-b993-bfa9538f8684"
  },
  {
      name: "Random Name #2",
      description: "Hello World!",
      url:"https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/promocion_2.jpg?alt=media&token=2f0a84dd-1750-4ad6-aa9f-523dbf8f252b"
  }
]


function Item(props)
{
    return (

        <Card>
        <CardActionArea>
          <CardMedia
            component="img"
            height="512px"
            image={props.item.url}
            alt="green iguana"
          />
          
        </CardActionArea>
        </Card>
    )
}


const width = "xxl"
export default function Services() {
 


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
      <>
      <Container maxWidth={width} sx={{
        backgroundImage: "radial-gradient(circle at top left, rgba(40, 40, 40, 0.79) 0%, rgba(40, 40, 40, 0.86) 100%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/image_hero.webp?alt=media&token=3e1d6b4b-b54a-48e7-b612-828e2bf36079') !important",
        pt:24,
        pb:24,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        
        }}>
        <Grid  container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={0} sm={0} md={1} align="center">
          
          </Grid>
          <Grid item xs={12} sm={12} md={4} align="left"> 
            <Stack spacing={2} >
              <Typography variant="h2" align="left" color="white" sx={{fontWeight:"bolder"}}>
                TodoFix
              </Typography>
              <Divider style={{ background: 'white' }}/>
              <Typography variant="h5" gutterBottom color="white" sx={{fontWeight:"bold"}} >
               "No tengas miedo de renunciar a lo bueno para ir a por lo mejor".
              </Typography>
              <Typography variant="body1" align="left" gutterBottom color="white">
                -John D. Rockefeller-
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} align="center">
          
          </Grid>
          <Grid item xs={12} sm={12} md={4} align="center">
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={width} sx={{
        pt:8,
        pb:8,
        backgroundColor:"#Fff"
      }}
      
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={6} >
            <Stack spacing={3}>
                <Typography variant="h2" align="left" color="#1E3F62"fontWeight="bold">
                  Nuestros servicios
                </Typography>
              </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
          </Grid>
          <Grid item xs={12} sm={12} md={3} >
          </Grid>
        </Grid>
      </Container>



      <Container maxWidth={width} sx={{
        pb:8,
        backgroundColor:"#Fff"
      }}
      
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center" component="main">
          <Grid item xs={12} sm={12} md={12} >
            <Accordion sx={{pl:3, margin:0}} disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            
            <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder" >
                
            Impermeabilización y pintura
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
            Interior y exterior
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            
            <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
            Trabajo en altura
            </Typography>
          </AccordionDetails>
            </Accordion>
            
            <Accordion sx={{pl:3}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                
                <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder" > 
                  Jardinería
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Plantación
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Mantenimiento
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Paisajismo
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{pl:3}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >  
                <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder">    
                  Albañilería
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                  Plomería
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                  Colocación de pisos
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                  Tabla roca
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                  Construcción en general
                </Typography>  
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{pl:3}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                
                <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder">
                    
                Intalaciones
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                  Electricidad
                  </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Sistemas de seguridad
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Sistemas de extracción
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Sistemas de refrigeración y aire acondicionado
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{pl:3}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder">
                      Limpieza y mantenimiento urbano
                  </Typography>
              </AccordionSummary>
            </Accordion>

            <Accordion sx={{pl:3}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder">
                  Oficios
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
              
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Carpitenría
                </Typography> 
              </AccordionDetails>
              <AccordionDetails>
                
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Herrería
                </Typography> 
              </AccordionDetails>
              <AccordionDetails>
                
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Cancelería
                </Typography> 
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{pl:3}} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="h4" align="left" color="#1E3F62" fontWeight="bolder">  
                  Remodelación
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body" align="left" color="#1E3F62" fontWeight="bolder" sx={{pl:4}}>
                Diseño de interiores
                </Typography> 
              </AccordionDetails>
            </Accordion>

          </Grid>
        </Grid>
      </Container>


     


      <Container maxWidth={"xxl"} align="center" disableGutters sx={{background:"white", pb:8}}>
        <Carousel sx={{width:"80%"}} indicators={false}>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
      </Container>



      
      <Container maxWidth={false} disableGutters>
        <Grid container alignItems="center" justifyContent="center" component="main"> 
          <Grid item xs={12} sm={12} md={6} >   
          <APIProvider apiKey={"AIzaSyBES2njD2zqvPzo9S_zwVzDZAkPM286Mi0"}>
              <Map
                style={{width: '100%', height: '577px'}}
                defaultCenter={{lat:  19.056248232911646, lng: -98.25806939274554}}
                defaultZoom={12}
                gestureHandling={'none'}
                disableDefaultUI={true}
              >
                <Marker position={{lat:  19.056248232911646, lng: -98.25806939274554}} />
              </Map>
            </APIProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6 } sx={{backgroundColor:"#252525"}}>
            <Stack spacing={2} sx={{padding:8}}>
              <Typography variant="h4" align="left" color="white" fontWeight="bolder">
                Contáctanos
              </Typography>
              <Divider flexItem sx={{ borderColor:"#fff", borderBottomWidth: '10px' ,backgroundColor:"#fff", maxWidth:"100px"}} />
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo"
                  name="email"
                  autoComplete="email"
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="telefono"
                  label="Teléfono"
                  name="telefono"
                  autoComplete="telephone"
                   variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="message"
                  label="Mensaje"
                  type="message"
                  id="message"     
                  multiline
                  rows={4}
                   variant="filled"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Enviar
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container> 
      </>
  );
}