import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardActionArea, CardMedia, Divider, Stack, TextField } from "@mui/material";
import { cyan } from "@mui/material/colors";

import { APIProvider, Marker, Map } from "@vis.gl/react-google-maps";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const projects = [
  {
    images: [
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-1%2Fmantenimiento-restaurante-1.jpeg?alt=media&token=586fb4d7-9b71-418a-a28b-24199256884b",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-1%2Fmantenimiento-restaurante-2.jpeg?alt=media&token=66751f81-c129-45e0-ba2d-3c73888e7a98",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-1%2Fmantenimiento-restaurante-3.jpeg?alt=media&token=d494cf34-4bfb-406e-b360-a031c446d3b3",
    ],
    description:
      "Mantenimiento e instalación a sistema de extracción en restaurante.",
  },
  {
    images: [
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-1.jpeg?alt=media&token=ce132774-f3dd-48cf-a425-089728e9c4de",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-2.jpeg?alt=media&token=fe3a5886-6a82-4afa-80e3-ce2243dcb0a2",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-3.jpeg?alt=media&token=3727b2b1-17c8-435f-b034-e9a3853b8f47",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-4.jpeg?alt=media&token=2c573dbb-cc66-411f-8222-386c93def4c2",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-5.jpeg?alt=media&token=64505ad4-7111-4089-bab0-60ac24bebbcb",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-6.jpeg?alt=media&token=9d0b4948-68f9-4fbf-aa74-eddda22df4a0",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-7.jpeg?alt=media&token=b4cb7eb1-fc0d-40b6-b322-56798407b446",
      "https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/proyectos%2Fproyecto-2%2Fmantenimiento-proyecto-8.jpeg?alt=media&token=47039310-931a-4f38-abd3-2c8015d432c5",
    ],
    description:
      "Remodelacion  local de comida; se realizó levantamiento y colocación  de piso, rediseño e instalación de sistema eléctrico, plomeria, instalación sanitaria e hidráulica.",
  },
];

const width = "xxl";
export default function Projects() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <>
      <Container
        maxWidth={width}
        sx={{
          backgroundImage:
            "radial-gradient(circle at top left, rgba(40, 40, 40, 0.79) 0%, rgba(40, 40, 40, 0.86) 100%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/image_hero.webp?alt=media&token=3e1d6b4b-b54a-48e7-b612-828e2bf36079') !important",
          pt: 24,
          pb: 24,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          component="main"
        >
          <Grid item xs={0} sm={0} md={2} align="center"></Grid>
          <Grid item xs={12} sm={12} md={8} align="center">
            <Stack spacing={2}>
              <Typography
                variant="h2"
                align="center"
                color="white"
                sx={{ fontWeight: "bolder" }}
              >
                TodoFix
              </Typography>
              <Typography variant="body1" gutterBottom color="white">
              En todo fix sabemos lo valioso que son los proyectos de nuestros clientes, 
              es por eso que para nosotros no hay  proyectos grandes ni pequeños; a todos les dedicamos el tiempo, 
              importancia, profesionalismo y puntualidad que cada uno merece.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={0} sm={0} md={2} align="center"></Grid>
        </Grid>
      </Container>

      <Container maxWidth={width} sx={{ pt: 8, pb: 8, backgroundColor:"#252525" }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          component="main"
        >
          <Grid item xs={12} sm={12} md={6}>
            <Slider {...settings}>
              {projects[0].images.map((item, index) => (
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="720px"
                      image={item}
                      alt={`imagen-proyecto1-${index}`}
                    />
                  </CardActionArea>
                </Card>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={2} align="center">
              <Typography variant="h4" align="center" color="white">
              Mantenimiento e instalación de sistemas de extracción
              </Typography>
              <Typography variant="body1" align="center" color="white">
                
              </Typography>
             
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={width}
        sx={{pt: 8, pb: 8 }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          component="main"
        >
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={2}>
              <Typography variant="h4" align="center" color="black">
              Trabajo de remodelación, sistema eléctrico, sanitario e hidráulico.
              </Typography>
              <Typography variant="body1" align="center" color="text.secondary">
              </Typography>
          
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} >
            <Slider {...settings}>
              {projects[1].images.map((item, index) => (
                 <Card>
                 <CardActionArea>
                   <CardMedia
                     component="img"
                     height="720px"
                     image={item}
                     alt={`imagen-proyecto1-${index}`}
                   />
                 </CardActionArea>
               </Card>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} disableGutters sx={{ marginTop: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          component="main"
        >
          <Grid item xs={12} sm={12} md={6}>
            <APIProvider apiKey={"AIzaSyBES2njD2zqvPzo9S_zwVzDZAkPM286Mi0"}>
              <Map
                style={{ width: "100%", height: "577px" }}
                defaultCenter={{
                  lat: 19.056248232911646,
                  lng: -98.25806939274554,
                }}
                defaultZoom={12}
                gestureHandling={"none"}
                disableDefaultUI={true}
              >
                <Marker
                  position={{
                    lat: 19.056248232911646,
                    lng: -98.25806939274554,
                  }}
                />
              </Map>
            </APIProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ backgroundColor: "#252525" }}>
            <Stack spacing={2} sx={{ padding: 8 }}>
              <Typography
                variant="h4"
                align="left"
                color="white"
                fontWeight="bolder"
              >
                Contactanos
              </Typography>
              <Divider
                flexItem
                sx={{
                  borderColor: "#fff",
                  borderBottomWidth: "10px",
                  backgroundColor: "#fff",
                  maxWidth: "100px",
                }}
              />
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="telefono"
                  label="Telefono"
                  name="telefono"
                  autoComplete="telephone"
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="message"
                  label="Message"
                  type="message"
                  id="message"
                  multiline
                  rows={4}
                  variant="filled"
                />
                <Button type="submit" fullWidth variant="contained">
                  Enviar
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
